.root{
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  width:100%;
}
.hidden{
  opacity: 0;
  pointer-events: none;

}

.header{
  background-color: var(--blue);
  min-height: 100px;
  max-height: 150px;
  height: 25%;
  display:flex;
  justify-content: center;
  align-items: center;
}

.header>span{
  display: inline-block;
  position: relative;
  width:80%;
}
.header>span>img{
  position: absolute;
  height:calc(100% - 12px);
  right:6px;
  top:6px;
  
}

.header>span>input{
  border-radius: 1.5rem;
  padding: 0.5rem 1rem;
  border:1px solid transparent;
  width:100%;

}
.header>span>input:focus{
  outline: none;
}
.content{
  position: relative;
  flex:1 1 auto;
}

.content::after{
  content:'';
  display:block;
  position: absolute;
  top:0;
  left:0;
  background-color: rgba(255,255,255,.5);
  width:100%;
  height: 100%;
  /*mask:url('./../images/circle.svg');
  mask-size: 70%;
  mask-position: center;
  mask-repeat: no-repeat;
  */
}

.map{height: 100%;}