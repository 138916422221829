.root{
  flex-direction: row;
  flex-wrap: wrap;
  --columns:1;
  justify-content: flex-start;
  align-items: stretch;
}
.root>*{
  width:100%;
  flex:1 1 auto;
  aspect-ratio: unset;
}
.img{width:100%}
.label{font-size: 80%;font-weight: bold;padding-left:.5rem;margin:0.3rem 0 0.8rem 0}
.value{font-size: 120%;font-weight:bold; color:var(--white);background-color: var(--black);padding:1rem;}
.value.center{width:80%;background-color: var(--blue);}

.value b{font-size: 150%;}
.text{font-size: 80%;padding:0.5rem;white-space: pre-line;}
