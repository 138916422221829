.root{
  --delay:1s;
  --delayStep:.1s;
  position: relative;
  background-color: var(--blue);
  color:var(--white);
  cursor: pointer;
  animation:viewAni 1s var(--delay) forwards;
  opacity: 0;
}

.root:nth-child(0){--delay:0s}
.root:nth-child(1){--delay:calc(var(--delayStep) * 1)}
.root:nth-child(2){--delay:calc(var(--delayStep) * 2)}
.root:nth-child(3){--delay:calc(var(--delayStep) * 3)}
.root:nth-child(4){--delay:calc(var(--delayStep) * 4)}
.root:nth-child(5){--delay:calc(var(--delayStep) * 5)}
.root:nth-child(6){--delay:calc(var(--delayStep) * 6)}
.root:nth-child(7){--delay:calc(var(--delayStep) * 7)}
.root:nth-child(8){--delay:calc(var(--delayStep) * 8)}

@keyframes viewAni {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50%{
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.root:hover, .root.active{
  background-color: var(--gray-dark);
}

.root>*{
  pointer-events: none;
  position: absolute;
  left:0;
  text-align: center;
  width: 100%;
}

.top{
  top:15px;
  font-size: 1.6rem;
}
.middle{
  font-size: min(20px, 1.6rem);
  font-size: 1.6rem;
  top:50%;
  transform: translateY(-50%);
  overflow: hidden;
}

.middle.icon{
  height:100%;
  top:0;
  transform: translateY(0);
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.middle.icon img{
  max-width:50%;  
  max-height:50%;
}


.middle.icon.chio img{
  width:60%;
  margin-top:10% 
}


.slideshow{
  --pos:0;
  height:100%;
  display:flex;
  flex-direction:row;
  justify-content: flex-start;
  align-items: center;
  
  transform: translateX(calc(var(--pos) * -100%));
  transition: transform .5s;
}
.slideshow>*{
  
  width:100%;
  height:100%;
  flex:0 0 auto;
}

.middle.icon .slideshow>*{
  height:100%;
  top:0;
  transform: translateY(0);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom{
  bottom:10px;
  font-size: .7rem;
}
