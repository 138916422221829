.root{
  position:absolute;
  top:6rem;
  left:1rem;
  right:1rem;
  bottom:2rem;
  
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  gap:1rem;
  background-color: var(--white);
  opacity: 0;
  animation: fadeInText .8s .2s;
  animation-fill-mode: forwards;
}
@keyframes fadeInText{
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}


.dots{
  background-color: rgba(0,0,0,0);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap:5px;
  height:3px;
  transition: backgroundColor 1s;
}
.dots.finished{
  background-color:var(--blue);
}


.header{}
.header h1{
  font-size:1.4rem;
  margin-top:1rem;
  line-height: 1.2;
  min-height: 3.4rem;
}

.content{
  flex:1 1 auto;
  background-color: var(--gray-light);
  padding:0.5rem;
  overflow-y: scroll;
  font-size: 80%;
  line-height: 1.2;
  white-space: pre-line;  
}

.footer{
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}
