
body{
  --white:white;
  --black:rgba(22,47,68,1);
  --gray-light:rgba(241,241,241,1);
  --gray-dark:rgba(141,162,186,1);
  --blue:rgba(0,157,223,1);
  
  background-color: var(--white);
  color:var(--black)
}

.btn_circle{
  
  background-color: var(--gray-light);
  color:var(--black);
  border-radius: 50%;

  width:40px;
  height:40px;
  display:flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn_circle>img{
  /*width:110%;
  height:110%;
  flex:0 0 auto;
  */
  width:100%;
  height:100%;
}
.btn_circle.active{
  background-color: var(--black);
  color:var(--white);
}


.mixed{
  font-weight: lighter;
}

.mixed b{  font-weight: bold;}