.root{
  position: absolute;
  display: flex;
  justify-content: center;
  
  width:100%;
  top:0;
  left:0;
  z-index: 4;
  
}
.root >*{
  position: absolute;
  top:2rem;

}
.root >*:first-child{
  left:1rem;
}
.root >*:last-child{
  right:1rem;
}


.logo{
  position: absolute;
  width:1096px;
  height:257px;
  top:2rem;
  transform-origin:top center;
  transform: scale(.15);
  transition: transform 1s, top 1s;
 }

.logo>*{
  position: absolute;
  top:0;
  left:0;
  height:253px;  
  transition: left 1s;
}

.logoCo{
  left:786px;
  top:4px;
  height:254px;
  opacity: 1;
  transition: height 0s .5s, opacity 0.5s 0.4s; 
}

:global(.intro) .logo{
  top:8rem;
  transform: scale(.40);  
}

:global(.intro) .logo .logoMain{
  left:150px;
}

:global(.intro) .logo .logoCo{
  height:0;
  opacity: 0;
  transition: height 0s .5s, opacity 0.5s 0s; 

}

.menuBtn{z-index: 5;}
