@font-face {
	font-family: 'Lato';
	src:url("Lato-Light.ttf");	
	font-weight: 100;
}

@font-face {
	font-family: 'Lato';
	src:url("Lato-Regular.ttf");	
	font-weight: 300;
}

@font-face {
	font-family: 'Lato';
	src:url("Lato-Bold.ttf");	
	font-weight: 700;
}



@media screen, print {html body{font-family: 'Lato', arial, helvetica, "Droid Sans", sans-serif;}}

